import React, { useRef } from "react"
import { $CandidatureSpontanee } from "./SC_CandidatureSpontanee"
import { ReactSVG } from "react-svg"
import Application from "../offerApplication"
import getUrlParameter from "../../dependentComponent/functions/getParamsValue"
import candidatureSpontaneeData from "./candidatureSpontaneeData"

const CandidatureSpontanee = () => {
  const childRef = useRef()

  return (
    <$CandidatureSpontanee>
      <div className="container-candidature-spontanee">
        <h3>{candidatureSpontaneeData.title}</h3>
        <span className="text">{candidatureSpontaneeData.description1}</span>
        <span className="text">{candidatureSpontaneeData.description2}</span>
        <span className="text">{candidatureSpontaneeData.description3}</span>
        <a
          className="postulationCTA"
          size="lg"
          href="https://stasinc.recruitee.com/o/candidatures-spontanees"
          target="_blank"
          // onClick={() => {
          //   childRef.current.handleOfferApplicationVisibility(true);
          // }}
        >
          {candidatureSpontaneeData.button}
        </a>
      </div>
      <Application
        jobId={10688}
        email={""}
        ref={childRef}
        formParams={getUrlParameter("form") ? true : false}
      />
      <ReactSVG
        className="candidature-spontanee-svg"
        src={`../../../../SVG/Groupe685.svg`}
      ></ReactSVG>
    </$CandidatureSpontanee>
  )
}

export default CandidatureSpontanee
