import React from "react";
import { $ImageHeaderSubPage } from "./SC_ImageHeaderSubPage.js";

const ImageHeaderSubPage = ({ NosUsine, usine }) => {
  return (
    <$ImageHeaderSubPage>
      <div className="image-header">
        <div className="bk-image"></div>
        <div className="over-bk-image"></div>
      </div>
    </$ImageHeaderSubPage>
  );
};

export default ImageHeaderSubPage;
