import React from "react";
import { $DescriptionUsine } from "./SC_DescriptionUsine";
import { ReactSVG } from "react-svg";
import descriptionUsineData from "./descriptionUsineData";
const DescriptionUsine = ({ NosUsine, usine }) => {
  const [stepState, setStepState] = React.useState(0);

  return (
    <$DescriptionUsine>
      <div className="description-container">
        <div className="description">
          <h3>{descriptionUsineData.descriptionTitle}</h3>
          {usine.paragrapheList.map((paragraph) => {
            return <span className="paragraphe">{paragraph.text}</span>;
          })}
        </div>
        <div className="operations">
          <h3>{descriptionUsineData.opérationsTitle}</h3>
          <div className="menu-operation">
            {usine.operationsList.map((operation, i) => {
              return (
                <span
                  className={`${i === stepState ? "true" : "false"}`}
                  onClick={() => setStepState(i)}
                >
                  {operation.title}
                </span>
              );
            })}
          </div>
          <div className="operation-text">
            {usine.operationsList.map((operationText, i) => {
              return (
                <div className={`text ${i === stepState ? "true" : "false"}`}>
                  {operationText.text}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="directeur-container">
        <div className="inner-text">
          <div className="inner-text-title">
            <ReactSVG
              className="icon-bulle"
              src={`../../../SVG/icon_bulle.svg`}
            ></ReactSVG>
            <h3>{descriptionUsineData.directeurTitle}</h3>
          </div>
          <span>{usine.directeurMessage}</span>
        </div>
      </div>
    </$DescriptionUsine>
  );
};

export default DescriptionUsine;
