import React from "react";
import { $WorkHere } from "./SC_WorkHere.js";

const WorkHere = ({ NosUsine, usine }) => {
  return (
    <$WorkHere>
      <div className="main-container">
        <div className="inner-text">
          <h3>
            <span>Pourquoi travailler à</span> <br />
            l’usine de {usine.ville}
          </h3>
        </div>
        <div className="box">
          <ul>
            {usine.pourquoiTravaillerList.map((raisons) => (
              <li>{raisons.text}</li>
            ))}
          </ul>
        </div>
      </div>
    </$WorkHere>
  );
};

export default WorkHere;
