import styled from "styled-components"
import BGImageCandidatureSpontanee from "../../../../static/Groupe741.webp"
import BGImageCandidatureSpontaneeTablet from "../../../../static/Groupe741-tablet.webp"
// import imgBackground from "../../../../static/";
export const $CandidatureSpontanee = styled.div`
  background: url(${BGImageCandidatureSpontanee}) no-repeat center;
  background-size: cover;
  padding: 1em 0;
  position: relative;

  @media only screen and (max-width: 768px) {
    background: url(${BGImageCandidatureSpontaneeTablet}) no-repeat center;
    background-size: cover;
  }

  /* margin-bottom: 1em; */
  .container-candidature-spontanee {
    z-index: 1;
    max-width: 55em;
    margin: 3em auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    h3 {
      z-index: 1;
      /* font-size: 2.5rem;
      font-weight: bold; */
    }
    .text {
      z-index: 1;
      padding: 1em 0;
      @media only screen and (max-width: 768px) {
        /* font-size: 1rem; */
      }
    }
    .postulationCTA {
      text-decoration: none;
      margin: 1.5em 0 0;
      width: 50%;
      min-width: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      /* width: 100%; */
      padding: 0.6em 2em;
      text-align: center;
      color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      background: url(/SVG/background-button-header.svg) no-repeat center center;
      background-size: contain;
      @media only screen and (min-width: 600px) {
        min-width: 400px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 18px;
      }

      &:hover {
      }
    }
  }
  .candidature-spontanee-svg {
    width: 30%;
    position: absolute;
    right: -1em;
    bottom: 0.1em;
    z-index: 0;
    @media only screen and (max-width: 768px) {
      display: none;
      width: 40%;
    }
  }
`
