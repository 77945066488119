import styled from "styled-components";
// import imgBackground from "../../../../static/";
export const $PostOfferPerCity = styled.div`
  padding: 1em 4em;
  margin-bottom: -8em;
  .poste-container {
    margin: 3em 1em;
    position: relative;
    .title-post {
      /* font-size: 50px; */
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 2em;
        height: 2em;
        background-color: fff;
        border: solid 1px #118738;
        border-radius: 50em;
        transform: translate(-40%, -50%);
      }
    }
    .post-list {
      width: 100%;
      box-shadow: 0 0 20px #555;
      margin: 2em 0;
      padding: 1em;
      background-color: #fff;
      .post {
        padding: 1em;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #eb5536;
        .icon-advantage {
          margin-right: 1em;
          div {
            svg {
              fill: #4fbec7;
            }
          }
        }
      }
    }
  }
`;
