const candidatureSpontaneeData = {
  title: "Le poste que tu désires n’est pas affiché?",
  description1:
    "Peut-être que ce poste n'est actuellement pas disponible, mais ça ne veut pas dire qu’il n’y aura pas de place pour toi chez STAS dans un avenir rapproché. Ici, ton talent n’a pas de frontière!",
  description2:
    "Sache que nous sommes constamment à la recherche d’ingénieur concepteur mécanique, ingénieur concepteur électrique et d’ingénieur à l'automatisation.",
  description3: "Laisse-nous tes coordonnées et nous te contacterons.",
  button: "Candidature spontanée",
};
export default candidatureSpontaneeData;
