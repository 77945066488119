import styled from "styled-components";
export const $ImageHeaderSubPage = styled.header`
  padding: 0;

  .image-header {
    height: 360px;
    width: 100%;
    position: relative;
    @media only screen and (max-width: 768px) {
      height: 175px;
    }

    .over-bk-image {
      background: red;
      position: absolute;
      background-size: contain;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
      left: 0;
    }
    .bk-image {
      background: red;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }
`;
